import { createRouter, createWebHistory } from 'vue-router'
import { app, pages } from '@/config'
import DefaultContainer from '@/components/external/DefaultContainer.vue'
import AdminContainer from '@/components/internal/AdminContainer.vue'
import { guardGlobal, guardAdmin, guardGuest, guardSales } from '@/utils/auth'

const HomePage = () => import(
  /* webpackChunkName: "p-external_home-page" */ '@/pages/external/HomePage.vue')
const ProductListPage = () => import(
  /* webpackChunkName: "p-external_product-list-page" */ '@/pages/external/ProductListPage.vue')
const ProductDetailPage = () => import(
  /* webpackChunkName: "p-external_product-detail-page" */ '@/pages/external/ProductDetailPage.vue')
const PurchaseOrderPage = () => import(
  /* webpackChunkName: "p-external_purchase-order-page" */ '@/pages/external/PurchaseOrderPage.vue')
const StockInPage = () => import(
  /* webpackChunkName: "p-external_stock-in-page" */ '@/pages/external/StockInPage.vue')
const LoginPage = () => import(
  /* webpackChunkName: "p-external_login-page" */ '@/pages/external/LoginPage.vue')
const AirConditionerInstallation = () => import(
  /* webpackChunkName: "p-external_login-page" */ '@/pages/external/AirConditionerInstallation.vue')

const InternalDashboardPage = () => import(
  /* webpackChunkName: "p-internal_dashboard-page" */ '@/pages/internal/DashboardPage.vue')
const PriceAdjustmentPage = () => import(
  /* webpackChunkName: "p-internal_price-adjustment-page" */ '@/pages/internal/PriceAdjustmentPage.vue')
const StockSummaryPage = () => import(
  /* webpackChunkName: "p-internal_stock-summary-page" */ '@/pages/internal/StockSummaryPage.vue')
const InternalPurchaseOrderPage = () => import(
  /* webpackChunkName: "p-internal_purchase-order-page" */ '@/pages/internal/PurchaseOrderPage.vue')
const InternalStoreOrderPage = () => import(
  /* webpackChunkName: "p-internal_store-order-page" */ '@/pages/internal/StoreOrderPage.vue')

const NotAuthorized = () => import(
  /* webpackChunkName: "c-not-authorized" */ '@/components/NotAuthorized.vue')
const NotFound = () => import(
  /* webpackChunkName: "c-not-found" */ '@/components/NotFound.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: pages.home,
      component: DefaultContainer,
      beforeEnter: guardGlobal,
      children: [
        {
          path: pages.home,
          name: app.routerName.home,
          component: HomePage,
          meta: {
            title: app.getAppTitle(app.routerName.home)
          }
        },
        {
          path: pages.productDetail,
          name: app.routerName.productDetail,
          component: ProductDetailPage,
          meta: {
            title: app.getAppTitle(app.routerName.productDetail)
          }
        },
        {
          path: pages.search,
          name: app.routerName.search,
          component: ProductListPage,
          meta: {
            title: app.getAppTitle(app.routerName.search)
          }
        },
        {
          path: pages.purchaseOrder,
          name: app.routerName.purchaseOrder,
          component: PurchaseOrderPage,
          beforeEnter: guardSales,
          meta: {
            title: app.getAppTitle(app.routerName.purchaseOrder)
          }
        },
        {
          path: pages.stockIn,
          name: app.routerName.stockIn,
          component: StockInPage,
          beforeEnter: guardSales,
          meta: {
            title: app.getAppTitle(app.routerName.stockIn)
          }
        },
        {
          path: pages.productList,
          name: app.routerName.productList,
          component: ProductListPage,
          meta: {
            title: app.getAppTitle(app.routerName.productList)
          }
        },
        {
          path: pages.login,
          name: app.routerName.login,
          component: LoginPage,
          beforeEnter: guardGuest,
          meta: {
            title: app.getAppTitle(app.routerName.login)
          }
        }
      ]
    },
    {
      path: pages.internal.home,
      component: AdminContainer,
      beforeEnter: guardAdmin,
      children: [
        {
          path: pages.internal.home,
          name: app.routerName.internal.home,
          component: InternalDashboardPage,
          meta: {
            title: app.getAppTitle(app.routerName.internal.home)
          }
        },
        {
          path: pages.internal.priceAdjustment,
          name: app.routerName.internal.priceAdjustment,
          component: PriceAdjustmentPage,
          meta: {
            title: app.getAppTitle(app.routerName.internal.priceAdjustment)
          }
        },
        {
          path: pages.internal.stockSummary,
          name: app.routerName.internal.stockSummary,
          component: StockSummaryPage,
          meta: {
            title: app.getAppTitle(app.routerName.internal.stockSummary)
          }
        },
        {
          path: pages.internal.purchaseOrder,
          name: app.routerName.internal.purchaseOrder,
          component: InternalPurchaseOrderPage,
          meta: {
            title: app.getAppTitle(app.routerName.internal.purchaseOrder)
          }
        },
        {
          path: pages.internal.storeOrder,
          name: app.routerName.internal.storeOrder,
          component: InternalStoreOrderPage,
          meta: {
            title: app.getAppTitle(app.routerName.internal.storeOrder)
          }
        }
      ]
    },
    {
      path: pages.thirdParty.acInstallation,
      name: app.routerName.thirdParty.acInstallation,
      component: AirConditionerInstallation,
      meta: {
        title: app.getAppTitle(app.routerName.thirdParty.acInstallation)
      }
    },
    {
      path: pages.notAuthorized,
      name: app.routerName.notAuthorized,
      component: NotAuthorized,
      meta: {
        title: app.getAppTitle(app.routerName.notAuthorized)
      }
    },
    {
      path: pages.notFound,
      name: app.routerName.notFound,
      component: NotFound,
      meta: {
        title: app.getAppTitle(app.routerName.notFound)
      }
    }
  ],
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
